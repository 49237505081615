import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (
  date: string | null | undefined,
  format: string,
  timezone?: string,
): string => {
  if (!date) {
    return "";
  } else {
    if (!timezone) {
      return dayjs(date).format(format);
    } else {
      return dayjs(date).tz(timezone).format(format);
    }
  }
};

export const now = (format: string): string => {
  return dayjs().format(format);
};

export const convertDateFormat = (dateString: string) => {
  const [datePart, timePart] = dateString.includes("T")
    ? dateString.split("T")
    : dateString.split(" ");

  if (datePart && timePart) {
    const cleanTimePart = timePart.replace(".000000Z", "");
    const formattedDate = `${datePart} * ${cleanTimePart}`;
    return dayjs(formattedDate).format("DD/MM/YYYY • h:mm A");
  }
  return "";
};
